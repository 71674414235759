<template>
  <button type="button" class="btn_color colorBox">
    <span
      :class="`${isClick ? 'on' : ''} colorPic`"
      :style="`background-color: ${color}`"
      @click="handleClick"
    />
  </button>
</template>

<script>
import { reactive, toRefs } from 'vue-demi';

export default {
  props: ['color', 'isClick'],
  setup(props, context) {
    const state = reactive({
      // isClick: false,
    });
    return {
      ...toRefs(state),
      handleClick() {
        const items = document.querySelectorAll('.colorPic');

        items.forEach((item) => {
          item.addEventListener('click', () => {
            items.forEach((e) => {
              e.classList.remove('on');
            });
            console.log(item);
            item.classList.add('on');
          });
        });
        context.emit('click', props.color);
      },
    };
  },
};
</script>

<style scoped>
.colorPic.on {
  width: 100%;
  height: 100%;
}
</style>
