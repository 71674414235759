<template>
  <div
    class="main_default_pop"
    :style="`top:${popup.pos_y}px;left:${popup.pos_x}px`"
  >
    <div class="modal_content">
      <div class="modal_wrap">
        <div class="pop_header disflex">
          <button
            type="button"
            class="leftclose btn_dim_close"
            @click="$emit('close', popup.spp_id)"
          ></button>
          <h2 class="new_title">{{ popup.popup_title }}</h2>
        </div>
        <!-- modal body-->
        <div class="modal_body">
          <a
            :href="popup.link_url"
            :target="popup.link_type === '1' ? '_blank' : ''"
          >
            <image-bg-src :src="popup.img_url" class="popup_img" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue-demi';
import ImageBgSrc from '@UI/ImageBlock/ImageBgSrc';

export default {
  name: 'MainPopup',
  components: { ImageBgSrc },
  props: ['popup'],
  setup(props) {
    const state = reactive({});

    return {
      ...toRefs(state),
      props,
    };
  },
};
</script>
<style scoped>
.main_default_pop {
  border: 1px solid;
  position: fixed;
  z-index: 300;
  width: 360px;
  height: auto;
  min-height: 400px;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #fff;
}
.main_default_pop .pop_header {
  max-height: 44px;
  padding: 10px 14px;
  line-height: 44px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 360px;
}
.main_default_pop . leftclose {
  bottom: 0;
}
.modal_wrap {
  overflow: auto;
  height: 100%;
  max-height: calc(100vh - 160px);
}
.modal_content {
  width: 100%;
  z-index: 102;
  overflow: hidden;
  align-self: center;
  background-color: white;
  height: auto;
}

.modal_body {
  height: initial;
  overflow: initial;
  padding-top: 44px;
}
.popup_img {
  position: relative;
  padding: 131% 0 0;
}
@media screen and (max-width: 550px) {
  .main_default_pop {
    width: calc(100% - 60px);
    left: 50% !important;
    transform: translateX(-50%);
  }
}
</style>
