<template>
  <section class="sildsns_wrap section outscroll">
    <div class="sinner">
      <h2 class="sec_title">{{ $t('Main.SILDINSNS') }}</h2>

      <swiper class="prod_swiper smallimg_swiper" :options="options">
        <template v-for="item in items">
          <swiper-slide :key="item.cit_id" class="swiper-slide">
            <a :href="item.link_url" target="_blank">
              <img :src="item.img_url" /> </a
          ></swiper-slide>
        </template>

        <div slot="scrollbar" class="swiper-scrollbar"></div>
      </swiper>
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

export default {
  components: { Swiper, SwiperSlide },
  props: ['title', 'items'],
  setup(props) {
    if (props.items) {
      props.items.filter((items) => {
        console.log(items);
        items.img_url =
          items.img_url.substr(0, 4) === 'http'
            ? items.img_url
            : process.env.VUE_APP_FILE_URL + items.img_url;
      });
    }
    return {
      options: {
        slidesPerView: 'auto',
        watchOverflow: true,
        scrollbar: {
          el: '.swiper-scrollbar',
        },
      },
    };
  },
};
</script>

<style>
.smallimg_swiper {
  position: relative;
}

.smallimg_swiper .swiper-scrollbar {
  bottom: -14px;
}
</style>
