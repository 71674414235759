<template>
  <div class="wrap main">
    <header-title-bassket active="home" />
    <div v-if="isLoading" class="layout_body">
      <!-- main_banner : start -->
      <main-banner :items="banners.banner" />
      <!-- main_banner : end -->
      <!-- 당신을 위한 추천 상품 : start -->
      <!--  상품이 샘플 데이터로 전달되어 지고 있어서 일단 주석처리 -->
      <template v-if="Array.isArray(recommends) && recommends.length > 0">
        <template v-for="recommend in recommends">
          <product-list
            v-if="recommend.items.length > 0"
            :key="recommend.ins_dtm"
            :items="recommend.items"
            :title="recommend.banner_title"
            class="recommend_wrap section"
          />
        </template>
      </template>
      <template v-else>
        <product-list :items="[]" class="recommend_wrap section" title="" />
      </template>

      <!-- 컨셉이 같은 유저들의 최근 관심상품 : start -->
      <template v-for="banner in banners.banner6">
        <product-list
          v-if="Array.isArray(banner.items) && banner.items.length > 0"
          :key="banner.ins_dtm"
          :items="banner.items"
          class="concept_wrap section"
          :title="banner.banner_title"
        />
      </template>
      <!-- 컨셉이 같은 유저들의 최근 관심상품  : end -->
      <!-- 중간배너 : start -->
      <template v-for="banner in banners.banner2">
        <only-image
          v-if="Array.isArray(banner.items) && banner.items.length > 0"
          :key="banner.ins_dtm"
          :items="banner.items"
        />
      </template>
      <!-- 중간배너 : end -->
      <!-- 이번주 업데이트 된 신상 : start -->
      <template v-for="banner in banners.banner4">
        <product-list
          v-if="Array.isArray(banner.items) && banner.items.length > 0"
          :key="banner.ins_dtm"
          :items="banner.items"
          class="week_update_wrap section"
          :title="banner.banner_title"
        />
      </template>
      <!-- 이번주 업데이트 된 신상 : end -->
      <!-- 랜덤 추천 : start // .outscroll 있어야 스크롤이 밖으로 빠짐-->

      <template v-for="banner in banners.banner5">
        <sm-big-image
          v-if="Array.isArray(banner.items) && banner.items.length > 0"
          :key="banner.ins_dtm"
          class="random_wrap section"
          :items="banners.banner5.items"
          :title="banners.banner5.banner_title"
        />
      </template>

      <!-- 카테고리 추천 : start -->
      <template v-for="banner in banners.banner7">
        <product-list
          v-if="Array.isArray(banner.items) && banner.items.length > 0"
          :key="banner.ins_dtm"
          class="category_wrap section"
          :items="banner.items"
          :title="banner.banner_title"
        />
      </template>

      <!-- 카테고리 추천 : end -->
      <!-- 중간배너2 : start -->
      <template v-for="banner in banners.banner3">
        <only-image
          v-if="Array.isArray(banner.items) && banner.items.length > 0"
          :key="banner.ins_dtm"
          :items="banner.items"
        />
      </template>
      <!-- 중간배너2 : end -->

      <!-- 컬러별 아이템 모음 : start -->
      <section v-if="Array.isArray(colorBanner) && colorBanner" class="coloritem_wrap section">
        <div class="sinner">
          <h2 class="sec_title">{{ $t('Main.ColorCollection') }}</h2>
          <color-swiper :colors="colors" @select="handleSelectColor" />
          <sm-two-image :key="colorBanner.spp_id" :items="colorBanner.items" />
        </div>
      </section>
      <!-- 컬러별 아이템 모음 : end -->
      <!-- 타임세일 : start -->
      <div v-if="Array.isArray(banners.banner9) && banners.banner9.length > 0" class="time_sale_wrap">
        <div v-if="banners.banner9.length > 0" class="time_sale_content">
          <div v-for="timsale in banners.banner9" :key="timsale.spp_id">
            <h2 class="time_sale_title" style="padding-left: 14px">
              {{ timsale.banner_title }}
            </h2>
            <time-sale
              :end_date="timsale.use_end_date"
              :end_time="timsale.use_end_time"
              :items="timsale.items"
              :start_date="timsale.use_start_date"
              :start_time="timsale.use_start_time"
            />
          </div>
        </div>
      </div>
      <!-- SILD IN SNS : start// .outscroll 있어야 스크롤이 밖으로 빠짐-->
      <template>
        <sns-image
          v-if="Array.isArray(banners.banner10) && banners.banner10.length > 0"
          :items="banners.banner10"
          :title="banners.banner10.banner_title"
        />
      </template>
      <!-- SILD IN SNS : end -->
      <company-info />
    </div>
    <div v-else class="loading_wrap">
      <div class="loading_content">
        <img src="../assets/images/design/loading.gif" alt="loading" />
      </div>
    </div>
    <div class="btn_top">
      <button
        type="button"
        class="top_move flex_right"
        @click="handleClickTop"
      ></button>
    </div>
    <MainPopup
      @close="handleClosePopup"
      :popup="item"
      :id="item.spp_id"
      v-for="item in popup"
      :key="item.spp_id"
    />
    <sild-footer isToggleNav="true" />
  </div>
</template>
<script>
import HeaderTitleBassket from '@Layouts/components/headers/HeaderTitleBasket.vue';
import SildFooter from '@Layouts/components/footers/SildFooter.vue';
import { ref, reactive, toRefs, onUpdated } from 'vue-demi';
import MainBanner from './main/MainBanner.vue';
import ProductList from './main/ProductList.vue';
import OnlyImage from './main/OnlyImage.vue';
import SmBigImage from './main/SmBigImage.vue';
import ColorSwiper from './main/ColorSwiper.vue';
import SmTwoImage from './main/SmTwoImage.vue';
import TimeSale from './main/TimeSale.vue';
import SnsImage from './main/SnsImage.vue';
import BannerAPI from '@/apis/BannerAPI';
import RecommendAPI from '@/apis/RecommendAPI';
import CommonAPI from '@/apis/CommonAPI';
import CompanyInfo from '../components/layouts/components/footers/CompanyInfo.vue';
import MainPopup from '@Views/MainPopup';
import EventAPI from '@/apis/EventAPI';
import { getCookie, setCookie } from '@Utils/GlobalUtils';

export default {
  components: {
    MainPopup,
    SildFooter,
    HeaderTitleBassket,
    MainBanner,
    ProductList,
    OnlyImage,
    SmBigImage,
    ColorSwiper,
    SmTwoImage,
    TimeSale,
    SnsImage,
    CompanyInfo,
  },

  setup(_, context) {
    const state = reactive({
      mainBnOption: null,
      banners: null,
      recommends: [],
      colors: [],
      colorBanner: null,
      testBanners: null,
      isLoading: false,
      popup: null,
      isMainPopup: false,
    });

    console.log('#########################');
    console.log(context);
    console.log(context.root.$t);
    console.log('#########################');

    const colorBanners = {};
    const clickedIndexes = ref([]);
    const recentPopup = JSON.parse(getCookie('popupState') ?? '[]');

    const handleMainPopup = async () => {
      const { data } = await EventAPI.getMainPopupList();
      state.popup = data.result_data;
    };

    handleMainPopup();
    const loadBanner = async () => {
      const { data } = await BannerAPI.getBannerList();
      return data;
    };

    const loadRecommend = async () => {
      const defaultItems = state.banners?.banner11 ?? [];

      defaultItems.forEach(async (defaultValue, idx) => {
        const recommend = { ...defaultValue };
        const { data } = await RecommendAPI.getMainRecommendList(
          idx + 1,
          defaultValue.items.length
        );

        recommend.items = recommend.items.map(
          (item, idx) => data.body.result.items[idx] ?? item
        );

        state.recommends = [...state.recommends, recommend];
      });
    };

    const loadColors = async () => {
      const { data } = await CommonAPI.getColors();
      // state.colors = data.result_data;
      // loadColorBanner(state.colors[0].color_code);
      return data;
    };

    const loadColorBanner = async (color) => {
      const { data } = await BannerAPI.getColorBanner(color);
      // state.colorBanner = data.rersult_data;
      // colorBanners[color] = data.rersult_data;
      console.log(data);
      return (state.colorBanner = data.rersult_data);
    };

    // loadBanner();
    // loadColors();

    Promise.all([loadBanner(), loadColors()])
      .then((result) => {
        console.log('@@@@@@@@');
        console.log(result);
        console.log('@@@@@@@@');
        state.banners = result[0];
        console.log(state.banners.banner10.length);
        loadRecommend();
        state.colors = result[1].result_data;
        let aa = loadColorBanner(state.colors[0] ? state.colors[0].color_code : '');

        state.colorBanner = aa.rersult_data;

        state.isLoading = true;
      })
      .catch((error) => {
        console.log(error);
      });
    onUpdated(() => {
      state.popup.filter((item) => {
        recentPopup.filter((idx) => {
          if (idx === item.spp_id) {
            let popups = document.getElementById(idx);

            popups.style.display = 'none';
          }
        });
      });
    });
    return {
      ...toRefs(state),
      clickedIndexes,
      handleSelectColor(color) {
        if (colorBanners[color]) {
          console.log(colorBanners[color]);
          state.colorBanner = colorBanners[color];
        } else {
          loadColorBanner(color);
        }
      },
      handleClickTop() {
        window.scrollTo(0, 0);
      },
      handleClosePopup(idx) {
        console.log('????', idx);
        const item = document.getElementById(idx);
        item.style.display = 'none';
        clickedIndexes.value.push(idx);

        console.log(clickedIndexes.value);
        setCookie('popupState', JSON.stringify(clickedIndexes.value));
      },
    };
  },
};
</script>

<style>
.main .layout_body {
  padding-top: 0;
}

.main .hinner {
  background-color: transparent;
  border-bottom: 0;
}

.main .hinner.bg {
  border-bottom: 1px solid #202020;
}

.loading_content {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 90px);
  background: transparent;
  z-index: 999;
}

.loading_content img {
  border-radius: 50em;
  height: 40px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  z-index: 100;
}

@media (min-width: 1240px) {
  .loading_content {
    max-width: 360px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .loading_content .timesale_wrap {
    width: 50%;
  }
}

/*
.time_sale_wrap .time_sale_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
*/
.time_sale_wrap .time_sale_title {
  font-size: 18px;
  padding-bottom: 14px;
}
</style>
