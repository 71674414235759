<template>
  <section class="outscroll">
    <div class="sinner">
      <h2 class="sec_title">{{ title }}</h2>
      <swiper class="prod_swiper smbigimg_swiper" :options="options">
        <template v-for="(group, idx) in groupItems">
          <template v-if="group.length === 2">
            <swiper-slide :key="idx" class="swiper-slide">
              <div class="sm_box">
                <product-image :item="group[0]" />
              </div>
              <div class="sm_box">
                <product-image :item="group[1]" />
              </div>
            </swiper-slide>
          </template>
          <template v-else>
            <swiper-slide :key="idx" class="swiper-slide">
              <product-image :item="group" />
            </swiper-slide>
          </template>
        </template>

        <!-- <swiper-slide class="swiper-slide">
          <a href="/sild/product/product_detail.php" class="prod_box">
            <image-block-file class="prod_img" fileName="main_01.png" />
          </a>
        </swiper-slide>

        <swiper-slide class="swiper-slide">
          <div class="sm_box">
            <a href="/sild/product/product_detail.php" class="prod_box">
              <image-block-file class="prod_img" fileName="img3.png" />
            </a>
          </div>
          <div class="sm_box">
            <a href="/sild/product/product_detail.php" class="prod_box">
              <image-block-file class="prod_img" fileName="img3.png" />
            </a>
          </div>
        </swiper-slide>
        <swiper-slide class="swiper-slide">
          <a href="/sild/product/product_detail.php" class="prod_box">
            <image-block-file class="prod_img" fileName="img3.png" />
          </a>
        </swiper-slide> -->
        <div slot="scrollbar" class="swiper-scrollbar" />
      </swiper>
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import ProductImage from '../../components/ui/ProductImage/ProductImage.vue';

export default {
  components: { Swiper, SwiperSlide, ProductImage },
  props: ['items', 'title'],
  setup({ items }) {
    const groupItems = [];
    for (let i = 0; i < items.length; ) {
      const temp = [];

      if (items[i]) temp.push(items[i++]);
      if (items[i]) temp.push(items[i++]);

      if (temp.length > 0) groupItems.push(temp);

      if (items[i]) groupItems.push(items[i++]);
    }

    return {
      groupItems,
      options: {
        slidesPerView: 'auto',
        watchOverflow: true,
        scrollbar: {
          el: '.swiper-scrollbar',
        },
      },
    };
  },
};
</script>

<style>
.smbigimg_swiper {
  position: relative;
}
.sm_box {
  width: 100%;
}
</style>
