<template>
  <section class="main_banner section">
    <div class="main_bnbox">
      <!-- Swiper -->
      <swiper ref="swiperWrapper" :options="options" class="mySwiper swiper">
        <swiper-slide v-for="item in items" :key="item.cit_id">
          <image-bg-src :src="item.img_url" />
        </swiper-slide>

        <div slot="pagination" class="swiper-pagination"></div>
      </swiper>
      <a
        class="btn_plus btn_pop_open"
        :title="$t('Main.Add')"
        @click="isShowEvent = true"
      />
    </div>

    <main-event-popup v-if="isShowEvent" @close="isShowEvent = false" />
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import { ref } from 'vue-demi';
import ImageBgSrc from '../../components/ui/ImageBlock/ImageBgSrc.vue';
import MainEventPopup from './MainEventPopup.vue';

let content = null;

export default {
  components: {
    Swiper,
    SwiperSlide,
    ImageBgSrc,
    MainEventPopup,
  },
  props: ['items'],
  setup() {
    const swiperWrapper = ref(null);

    const options = ref({
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    });

    const isShowEvent = ref(false);

    return {
      options,
      isShowEvent,
      swiperWrapper,
    };
  },
  mounted() {
    content = document.querySelector('.main_banner');

    if (content) {
      navgationBgControll();
      window.addEventListener('scroll', navgationBgControll);
    }
  },
  destroyed() {
    content && window.removeEventListener('scroll', navgationBgControll);
  },
};

const navgationBgControll = () => {
  if (content) {
    const isOver = content.clientHeight < window.scrollY;

    const className = `hinner ${isOver ? 'bg' : ''}`;

    document.querySelector('.main .hinner').className = className;
  }
};
</script>

<style>
.main_bnbox .swiper-wrapper {
  display: flex;
}

.mySwiper.swiper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.mySwiper .btn_plus {
  position: absolute;
  right: 14px;
  bottom: 14px;
  z-index: 10;
}

.main_bnbox .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.mySwiper .swiper-slide img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-pagination {
  display: flex;
  justify-content: center;
  left: 0;
  bottom: 6px;
  transform: translate3d(0px, -50%, 0);
}

.main_bnbox .btn_plus {
  position: absolute;
  z-index: 100;
  bottom: 10px;
  right: 10px;
}
</style>
