import { sinkerAPI as axios } from '@/plugins/axios';

export default {
  getRecommendList(recommend_number) {
    return axios().get('recommend/test', {
      params: {
        recommend_number,
      },
    });
  },

  getMainRecommendList(recommend_number, size) {
    const mem_id = localStorage.getItem('mem_id') ?? 0;
    let recent_view_items = JSON.parse(
      localStorage.getItem('recent-items') ?? '[]',
    );
    recent_view_items =
      recent_view_items.length > 20
        ? recent_view_items.splice(0, 20)
        : recent_view_items; // 최근 조회 상품 20개
    // TODO: 최근 구매
    let recent_buy_items = [];
    // recent_buy_items =
    //   recent_buy_items.length > 10
    //     ? recent_buy_items.splice(0, 10)
    //     : recent_buy_items; // 최근 구매 상품 10개
    return axios().post('recommend/main', {
      recommend_number: recommend_number,
      mem_id: mem_id,
      recent_view_items: recent_view_items,
      recent_buy_items: recent_buy_items,
      size: size,
      simple: true,
    });
  },
};
