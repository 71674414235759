<template>
  <section id="time_sale_content" class="timesale_wrap section">
    <div class="sinner">
      <div class="prod_swiper noswiper_prod">
        <swiper :options="swiperOption">
          <swiper-slide
            v-for="timeSaleItems in timeSaleItem"
            :key="timeSaleItems.is_sild_no"
            class="time_sale_product_content"
          >
            <product-item :bookMark="true" :item="timeSaleItems">
              <div slot="inImageDown" class="timesal_box">
                <p class="clack"></p>
                <p class="clack_num">
                  <span>{{ hours }} : {{ mins }} : {{ seconds }}</span>
                </p>
              </div>
            </product-item>
          </swiper-slide>
        </swiper>
        <!--        <p class="clack_num">-->
        <!--          <span>{{ hours }} : {{ mins }} : {{ seconds }}</span>-->
        <!--        </p>-->
        <ul>
          <li></li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import ProductItem from '../../components/ui/ProductItem/ProductItem.vue';
import { reactive, toRefs, watch } from 'vue-demi';
import moment from 'moment';
import { toString } from 'lodash';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

export default {
  props: ['items', 'start_date', 'end_date', 'start_time', 'end_time'],
  components: { ProductItem, Swiper, SwiperSlide },
  setup(props) {
    const state = reactive({
      startTime: null,
      endTime: null,
      startSeconds: null,
      endSeconds: null,
      hours: '',
      mins: '',
      seconds: '',
      intervalTiem: null,
      timeSaleItem: props.items,
    });

    const timeProcessing = () => {
      state.intervalTiem = setInterval(function () {
        state.startTime = props.start_date;
        state.endTime = props.end_date;
        state.startSeconds = props.start_time;
        state.endSeconds = props.end_time;
        const toDate = moment();
        const endDate = moment(
          '' + state.endTime + ' ' + state.endSeconds + '',
        );
        const remnantTimes = moment.duration(endDate.diff(toDate)).asSeconds();
        function times(seconds) {
          let hour =
            parseInt(seconds / 3600) < 10
              ? '0' + parseInt(seconds / 3600)
              : parseInt(seconds / 3600);
          let min =
            parseInt((seconds % 3600) / 60) < 10
              ? '0' + parseInt((seconds % 3600) / 60)
              : parseInt((seconds % 3600) / 60);
          let sec = toString(
            Math.floor(seconds % 60 < 10 ? '0' + (seconds % 60) : seconds % 60),
          );
          state.hours = hour;
          state.mins = min;
          state.seconds = sec;
        }
        times(remnantTimes);
      }, 1000);
    };

    timeProcessing();

    watch(
      () => state.seconds,
      newValue => {
        state.seconds = newValue;
      },
    );
    watch(
      () => state.mins,
      newValue => {
        state.mins = newValue;
      },
    );
    watch(
      () => state.hours,
      newValue => {
        state.hours = newValue;
      },
    );

    return {
      ...toRefs(state),
      swiperOption: {
        slidesPerView: 'auto',
        watchOverflow: true,
        spaceBetween: 10,
      },
    };
  },
  destroyed() {
    clearInterval(this.intervalTiem);
  },
};
</script>

<style scoped>
/*.timesale_wrap {*/
/*  width: 50%;*/
/*}*/
.time_sale_product_content {
  width: calc((100% / 2) - 8px);
}
#time_sale_content .prod_swiper.noswiper_prod li {
  width: 100%;
}
</style>
