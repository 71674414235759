<template>
  <swiper class="nomal_swiper color_swiper" :options="options">
    <swiper-slide v-for="(color, index) in colors" :key="color.spp_id">
      <color-button
        :isClick="index == 0 ? true : false"
        :color="color.color_code"
        @click="handleClickColor"
      />
    </swiper-slide>
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import ColorButton from '../../components/ui/Buttons/ColorButton.vue';

export default {
  props: ['colors'],
  components: {
    Swiper,
    SwiperSlide,
    ColorButton,
  },
  setup(_, context) {
    return {
      options: {
        slidesPerView: 'auto',
        watchOverflow: true,
      },

      handleClickColor(color) {
        context.emit('select', color);
      },
    };
  },
};
</script>

<style></style>
