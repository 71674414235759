<template>
  <article class="sub_banner mainsub1_banner">
    <div class="sinner">
      <swiper
        :options="options"
        class="swiper prod_swiper onlyimg_swiper onlyimg"
      >
        <swiper-slide v-for="item in items" :key="item.cit_id">
          <product-image :item="item" />
        </swiper-slide>

        <div slot="scrollbar" class="swiper-scrollbar"></div>
      </swiper>
    </div>
  </article>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import ProductImage from '../../components/ui/ProductImage/ProductImage.vue';

export default {
  components: { Swiper, SwiperSlide, ProductImage },
  props: ['items'],
  setup() {
    return {
      options: {
        slidesPerView: 'auto',
        centeredSlides: true,
        watchOverflow: true,
        scrollbar: {
          el: '.swiper-scrollbar',
        },
      },
    };
  },
};
</script>

<style>
.prod_swiper.onlyimg > div > div {
  width: 88%;
}
.prod_swiper.onlyimg .swiper-slide:first-child {
  margin-left: 20px;
}
.swiper-slide a {
  display: block;
}
.swiper-scrollbar {
  position: absolute;
  z-index: 10;
  bottom: 20px;
  width: 80%;
  left: 50%;
  transform: translateX(-50%);
}
.sub_banner .image-bg-src {
  object-position: center center !important;
}
</style>
