<template>
  <swiper class="prod_swiper smtwoimg_swiper" :options="options">
    <swiper-slide v-for="group in groupItems" :key="group[0].cit_id">
      <div class="sm_box">
        <product-item :item="group[0]" :bookMark="true" />
      </div>
      <div v-if="group[1]" class="sm_box">
        <product-item :item="group[1]" :bookMark="true" />
      </div>
    </swiper-slide>
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import ProductItem from '../../components/ui/ProductItem/ProductItem.vue';

export default {
  name: 'ProductSwiperList',
  props: ['items'],
  components: { Swiper, SwiperSlide, ProductItem },
  setup({ items }) {
    const groupItems = [];

    for (let i = 0; i < items.length; ) {
      const item = [];

      if (items[i]) item.push(items[i++]);
      if (items[i]) item.push(items[i++]);

      if (item.length > 0) groupItems.push(item);
    }

    return {
      groupItems,
      options: {
        slidesPerView: 'auto',
        watchOverflow: true,
      },
    };
  },
};
</script>

<style></style>
