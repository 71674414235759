<template>
  <down-full-up-popup
    :title="$t('Main.EventAll')"
    class="main-event-popup"
    @close="$emit('close')"
  >
    <div slot="body" class="event_banner">
      <image-block-src
        v-for="item in items"
        :key="item.spp_id"
        :src="item.img_url"
      />
    </div>
  </down-full-up-popup>
</template>

<script>
import DownFullUpPopup from '../../components/popups/DownFullUpPopup.vue';
import BannerAPI from '@/apis/BannerAPI';
import { reactive, toRefs } from 'vue-demi';
import ImageBlockSrc from '../../components/ui/ImageBlock/ImageBlockSrc.vue';
export default {
  components: { DownFullUpPopup, ImageBlockSrc },

  setup() {
    const state = reactive({
      items: [],
    });

    const loadDatas = async () => {
      const { data } = await BannerAPI.getMainBanner();

      state.items = data.result_data;
    };

    loadDatas();

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style>
.main-event-popup .pop_body,
.main-event-popup .layout_box {
  padding: 0;
}
</style>
