import { axios } from '@/plugins/axios';

export default {
  getBannerList() {
    return axios.post('banner/banner_list');
  },

  getColorBanner(color) {
    return axios.post('banner/getColorBanner', { color });
  },

  getMainBanner() {
    return axios.post('banner/getMainBanner');
  },
};
